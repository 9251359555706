import { serviceConstants } from './constant';
import { IPrevAdminToolSettingsState } from '../interfaces';
export const setAdminToolSettingHandler = (
  adminToolState: string,
  setAdminToolSettings: (
    value: (prevState: any) => {
      telephoneNumber: string;
      customerLogin: boolean;
      customerTelephoneService: boolean;
      sisterBrandLink: boolean;
      disableFields: boolean;
    }
  ) => void
) => {
  switch (adminToolState) {
    case 'disableFields': {
      setAdminToolSettings((prevState) => {
        if (prevState.disableFields) {
          return {
            telephoneNumber: prevState.telephoneNumber,
            customerLogin: false,
            customerTelephoneService: false,
            sisterBrandLink: false,
            disableFields: !prevState.disableFields
          };
        } else {
          return {
            ...prevState,
            disableFields: !prevState.disableFields
          };
        }
      });
      break;
    }
    case 'customerLogin': {
      setAdminToolSettings((prevState) => {
        return {
          ...prevState,
          customerLogin: !prevState.customerLogin
        };
      });
      break;
    }
    case 'customerTelephoneService': {
      setAdminToolSettings((prevState) => {
        return {
          ...prevState,
          customerTelephoneService: !prevState.customerTelephoneService
        };
      });
      break;
    }
    case 'sisterBrandLink': {
      setAdminToolSettings((prevState) => {
        return {
          ...prevState,
          sisterBrandLink: !prevState.sisterBrandLink
        };
      });
      break;
    }
  }
};

export const getAccessTokenHandler = () => {
  const token = JSON.parse(
    localStorage.getItem(serviceConstants.OKTA_TOKEN_KEY) as string
  )?.accessToken;
  if (token) {
    return token;
  } else {
    return ' ';
  }
};

export const adminToolStateKeyName = (toolStateKeyName: string) => {
  let stateKeyMap = '';
  switch (toolStateKeyName) {
    case 'disableFields': {
      stateKeyMap = 'Crisis Tool';
      break;
    }
    case 'customerLogin': {
      stateKeyMap = 'Customer login form';
      break;
    }
    case 'customerTelephoneService': {
      stateKeyMap = 'Telephone number';
      break;
    }
    case 'sisterBrandLink': {
      stateKeyMap = 'Sister Brands link';
      break;
    }
    case 'telephoneNumber': {
      stateKeyMap = 'Service Number';
      break;
    }
  }
  return stateKeyMap;
};

export const stateMapping = (state: boolean) => {
  if (state) {
    return 'ON';
  } else {
    return 'OFF';
  }
};

export const checkStateChangeHandler = (
  prevState: IPrevAdminToolSettingsState,
  nextState: IPrevAdminToolSettingsState
) => {
  let updateFlag = false;
  let key: keyof typeof prevState;
  for (key in prevState) {
    if (prevState[key] !== nextState[key]) {
      updateFlag = true;
      break;
    }
  }
  return updateFlag;
};

export const buttonTextMap = (flag: boolean) => {
  if (flag) {
    return 'Save changes';
  } else {
    return 'Activate Crisis Tool';
  }
};

export const adminToolContentMapping = (flag: boolean) => {
  if (flag) {
    return 'The Crisis Tool is currently active. To disable it now, click here:';
  } else {
    return 'In the event of a major outage, traffic will be redirected via DNS to a static splash page. When the Crisis Tool is ready to handle guest/partner enquiries, you can access the options for it here:';
  }
};

export const truncate = (string: string, limit: number) => {
  if (string.length <= limit) {
    return string;
  } else {
    return string.slice(0, limit) + '...';
  }
};
