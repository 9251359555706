import React from 'react';
import styles from './AdminToolAlert.module.css';
import classNames from 'classnames';
import VectorError from '../../assets/images/VectorError.png';
import VectorSuccess from '../../assets/images/VectorSuccess.png';
import { alertIcon } from '../../utils/constant';

interface IAdminToolAlert {
  alertType: string;
}

interface IAlertContent {
  alertContainerStyle: string;
  imgAltText: string;
  headerText: string;
  contentText: string;
}

const AlertContent = ({
  alertContainerStyle,
  imgAltText,
  headerText,
  contentText
}: IAlertContent) => {
  let imgSrc;
  if (imgAltText === alertIcon.VectorSuccess) {
    imgSrc = VectorSuccess;
  } else if (imgAltText === alertIcon.VectorError) {
    imgSrc = VectorError;
  }
  return (
    <div className={alertContainerStyle}>
      <img
        className={styles.VectorIconImg}
        src={imgSrc}
        alt={alertIcon[imgAltText as keyof typeof alertIcon]}
      />
      <div className={styles.adminToolAlertContentContainer}>
        <p>
          <strong className={styles.alertContentHeaderText}>{headerText}</strong>
        </p>
        <p className={styles.alertContentText}>{contentText}</p>
      </div>
    </div>
  );
};

const AlertContentProps: Record<string, IAlertContent> = {
  Success: {
    alertContainerStyle: classNames(
      styles.adminToolAlertContainer,
      styles.adminToolSuccessAlertStyle
    ),
    imgAltText: 'VectorSuccess',
    headerText: 'Changes were successful!',
    contentText:
      'Your change was successfully saved and is now reflected on the customer-facing website.'
  },
  Error: {
    alertContainerStyle: classNames(
      styles.adminToolAlertContainer,
      styles.adminToolErrorAlertStyle
    ),
    imgAltText: 'VectorError',
    headerText: 'Sorry! There was an error saving your changes - please try again.',
    contentText:
      'We encountered a problem when trying to save your changes - please wait a moment and try again.'
  }
};

const AdminToolAlert = ({ alertType }: IAdminToolAlert) => {
  return <AlertContent {...AlertContentProps[alertType]} />;
};

export default AdminToolAlert;
