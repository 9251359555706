import React, { useEffect, useRef, useState } from 'react';
import styles from './Admin.module.css';
import Header from '../../components/header/Header';
import AdminToggleSwitch from '../../components/adminToggleSwitch/AdminToggleSwitch';
import AdminTelephoneInput from '../../components/adminTelephoneInput/AdminTelephoneInput';
import AdminToolContent from '../../components/adminToolContent/AdminToolContent';
import classNames from 'classnames';
import { readBookingAdminToolSettings, saveBookingAdminToolSettings } from '../../apiCall';
import {
  IAdminOnSubmitInput,
  IAdminsettings,
  IAdminToolFormValues,
  IPrevAdminToolSettingsState
} from '../../interfaces';
import { trackPromise } from 'react-promise-tracker';
import { Field, Formik, FormikErrors } from 'formik';
import { useOktaAuth } from '@okta/okta-react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  adminToolContentMapping,
  buttonTextMap,
  checkStateChangeHandler,
  getAccessTokenHandler,
  setAdminToolSettingHandler
} from '../../utils/utils';
import Spinner from '../../components/spinner/Spinner';
import { alerts, apiConstants, toastConstants } from '../../utils/constant';
import ConfirmationContent from '../../components/confirmationContent/ConfirmationContent';
import AdminToolAlert from '../../components/adminToolAlert/AdminToolAlert';

const Admin = () => {
  /* admin components states */
  const [adminToolSettings, setAdminToolSettings] = useState({
    disableFields: false,
    customerLogin: false,
    customerTelephoneService: false,
    sisterBrandLink: false,
    telephoneNumber: ''
  });
  const [adminToolStatus, setAdminToolStatus] = useState(false); // status of the admin tool
  const [showCrisisToolDeactivationButton, setShowCrisisToolDeactivationButton] = useState(false); // to show deactivation crisis tool button
  const [saveButton, setSaveButton] = useState(false); // enable and disable save button style state
  const [showConfirmationModal, setShowConfirmationModal] = useState(false); // show confirmation model
  const [showAlert, setShowAlert] = useState({
    isAlert: false,
    alertType: ''
  });
  const [deactivatedByButton, setDeactivatedByButton] = useState(false);
  /* admin components states */

  const { oktaAuth, authState } = useOktaAuth();
  const prevAdminToolSettingsRef = useRef<IPrevAdminToolSettingsState>(adminToolSettings); // To capture prev state values
  let authAccessToken = '';
  if (authState) {
    if (authState.accessToken) {
      authAccessToken = authState.accessToken.accessToken;
    }
  }

  useEffect(() => {
    if (process.env.REACT_APP_OKTA === 'true' && authState?.isAuthenticated === false) {
      oktaAuth.signInWithRedirect({});
    }
  }, [oktaAuth, authState?.isAuthenticated]);

  useEffect(() => {
    if (authState) {
      authState.isAuthenticated &&
        trackPromise(
          readBookingAdminToolSettings()
            .then((adminSettings) => {
              if (adminSettings.settings) {
                setAdminToolSettings({
                  disableFields: adminSettings.settings.enableAdvanceFunctionallity,
                  customerLogin: adminSettings.settings.showCustLogin,
                  customerTelephoneService: adminSettings.settings.showCustServiceTelephone,
                  sisterBrandLink: adminSettings.settings.showSisterBrandLink,
                  telephoneNumber: adminSettings.settings.phNos[0].trim()
                });
                prevAdminToolSettingsRef.current = {
                  disableFields: adminSettings.settings.enableAdvanceFunctionallity,
                  customerLogin: adminSettings.settings.showCustLogin,
                  customerTelephoneService: adminSettings.settings.showCustServiceTelephone,
                  sisterBrandLink: adminSettings.settings.showSisterBrandLink,
                  telephoneNumber: adminSettings.settings.phNos[0].trim()
                };
                if (adminSettings.settings.enableAdvanceFunctionallity) {
                  setAdminToolStatus(true); // set admin tool status to "active"
                  setSaveButton(true); // sets save button to "enabled" state
                  setShowCrisisToolDeactivationButton(true); //show deactivation crisis tool button
                } else {
                  setSaveButton(false); // sets save button to "disabled" state
                  setShowCrisisToolDeactivationButton(false);
                }
              } else {
                throw Error(apiConstants.FETCH_FAIL);
              }
            })
            .catch((error) => {
              toast.error(error.message);
            })
        );
    }
  }, [authState]);

  const EnableAndDisableAdminToolSettingsStyle = classNames(
    styles.adminContinuityToolSettings,
    { [styles.enabledFields]: adminToolSettings.disableFields },
    { [styles.disableFields]: !adminToolSettings.disableFields }
  );
  const EnableAndDisableSaveButtonStyle = classNames(
    styles.abuContinuitySaveButton,
    { [styles.enabledSaveButton]: saveButton },
    { [styles.disableSaveButton]: !saveButton }
  );

  const validate = (values: IAdminToolFormValues) => {
    const errors: FormikErrors<IAdminToolFormValues> = {};
    if (!values.telephoneNumber) {
      errors.telephoneNumber = 'isEmpty';
    } else if (!new RegExp('^[+(\\s.\\-\\/\\d)]{5,30}$').test(values.telephoneNumber.toString())) {
      errors.telephoneNumber = 'isInvalid';
    }
    return errors;
  };
  const onSubmitForHandler = (
    values: IAdminToolFormValues,
    { setSubmitting }: IAdminOnSubmitInput
  ) => {
    setSubmitting(true);
    const adminSettings = {
      disableFields: values.disableFields,
      customerLogin: values.customerLogin,
      customerTelephoneService: values.customerTelephoneService,
      sisterBrandLink: values.sisterBrandLink,
      telephoneNumber: values.telephoneNumber
    };
    if (adminSettings.disableFields) {
      if (
        adminSettings.customerLogin ||
        adminSettings.customerTelephoneService ||
        adminSettings.sisterBrandLink
      ) {
        if (checkStateChangeHandler(prevAdminToolSettingsRef.current, adminSettings)) {
          setShowConfirmationModal(true);
        } else {
          toast.error(toastConstants.NO_CHANGE_ADMIN_SETTINGS);
        }
      } else {
        toast.error(toastConstants.ADMIN_SETTINGS_VALIDATION);
      }
    } else {
      setShowCrisisToolDeactivationButton(false);
      setShowConfirmationModal(true);
    }
    setSubmitting(false);
  };

  const updateBookingSettings = (adminSettings: IAdminToolFormValues) => {
    const updateAdminSettings: IAdminsettings = {
      enableAdvanceFunctionallity: adminSettings.disableFields,
      showCustLogin: adminSettings.customerLogin,
      showCustServiceTelephone: adminSettings.customerTelephoneService,
      phNos: [adminSettings.telephoneNumber],
      showSisterBrandLink: adminSettings.sisterBrandLink
    };

    trackPromise(
      saveBookingAdminToolSettings(updateAdminSettings, getAccessTokenHandler(), authAccessToken)
        .then((updateSettings) => {
          if (
            updateSettings.message === apiConstants.UNAUTHORIZED ||
            updateSettings.status !== 200
          ) {
            setShowAlert({ isAlert: true, alertType: alerts.ERROR });
            throw new Error('Update failed');
          } else {
            prevAdminToolSettingsRef.current = {
              disableFields: adminSettings.disableFields,
              customerLogin: adminSettings.customerLogin,
              customerTelephoneService: adminSettings.customerTelephoneService,
              sisterBrandLink: adminSettings.sisterBrandLink,
              telephoneNumber: adminSettings.telephoneNumber
            };
            if (adminSettings.disableFields) {
              setShowCrisisToolDeactivationButton(true); // show deactivation crisis tool button
              setAdminToolStatus(true); // set admin tool status to "active"
            } else {
              setShowCrisisToolDeactivationButton(false); // show deactivation crisis tool button
              setAdminToolStatus(false); // set admin tool status to "active"
            }
            deactivatedByButton && setSaveButton(false);
            setShowAlert({ isAlert: true, alertType: alerts.SUCCESS });
            setDeactivatedByButton(false);
          }
        })
        .catch((error) => {
          deactivatedByButton && setSaveButton(true);
          setShowCrisisToolDeactivationButton(prevAdminToolSettingsRef.current.disableFields);
          setAdminToolSettings({
            ...prevAdminToolSettingsRef.current,
            telephoneNumber: adminSettings.telephoneNumber
          });
          setSaveButton(prevAdminToolSettingsRef.current.disableFields);
          error && setShowAlert({ isAlert: true, alertType: alerts.ERROR });
        })
    );
  };

  const handleConfirmationContentModal = (flag: boolean) => {
    setShowConfirmationModal(flag);
  };

  const handleUpdateSettings = (flag: boolean) => {
    if (flag) {
      updateBookingSettings(adminToolSettings);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowAlert({ isAlert: false, alertType: '' });
    }, 10000);
    return () => clearTimeout(timer);
  }, [showAlert.alertType]);

  if (!authState?.isAuthenticated) {
    return (
      <div data-testid="adminToolLoadingTestId">
        <p>.....Loading</p>
        <Spinner />
      </div>
    );
  }

  return (
    <div className={styles.admin} data-testid="adminToolTestId">
      <Header activateAdminToolStatus={adminToolStatus} />
      {showAlert.isAlert && <AdminToolAlert alertType={showAlert.alertType} />}
      <div className={styles.adminToolContentContainer}>
        <AdminToolContent />
        <div className={styles.adminToolSettings}>
          <p>{adminToolContentMapping(showCrisisToolDeactivationButton)}</p>
          <div className={styles.adminAbuContinuityAdvanceSettings} data-testid="formikTestId">
            <Formik
              enableReinitialize={true}
              initialValues={{
                disableFields: adminToolSettings.disableFields,
                customerLogin: adminToolSettings.customerLogin,
                customerTelephoneService: adminToolSettings.customerTelephoneService,
                sisterBrandLink: adminToolSettings.sisterBrandLink,
                telephoneNumber: adminToolSettings.telephoneNumber
              }}
              values={adminToolSettings}
              validate={validate}
              onSubmit={onSubmitForHandler}>
              {({ values, errors, touched, handleBlur, setFieldValue, handleSubmit }) => (
                <form className={styles.adminToolForm} onSubmit={handleSubmit}>
                  <div className={styles.advanceFunctionalitySetting}>
                    {showCrisisToolDeactivationButton ? (
                      <button
                        data-testid="deactivateAdminToolButtonTestId"
                        type="submit"
                        className={styles.deactivationButtonStyle}
                        onClick={() => {
                          setFieldValue('disableFields', false);
                          setAdminToolSettingHandler('disableFields', setAdminToolSettings);
                          setSaveButton(false);
                          setDeactivatedByButton(true);
                        }}>
                        Deactivate Crisis Tool
                      </button>
                    ) : (
                      <>
                        <Field
                          isOn={values.disableFields}
                          component={AdminToggleSwitch}
                          handleToggle={() => {
                            setFieldValue('disableFields', !values.disableFields);
                            setAdminToolSettingHandler('disableFields', setAdminToolSettings);
                            setSaveButton(!values.disableFields);
                            setShowCrisisToolDeactivationButton(false);
                          }}
                          id={'advance-functionality-settings'}
                        />
                        <p>Access advanced settings</p>
                      </>
                    )}
                  </div>

                  <div className={EnableAndDisableAdminToolSettingsStyle}>
                    <h3 className={styles.ContinuityToolContentSettings}>Crisis Tool settings</h3>
                    <p>
                      Here you can indicate which pieces of information should be displayed to
                      visitors, as well as update key pieces of information.
                    </p>
                    <div className={styles.customerLoginForm}>
                      <Field
                        isOn={values.customerLogin}
                        component={AdminToggleSwitch}
                        handleToggle={() => {
                          adminToolSettings.disableFields &&
                            setFieldValue('customerLogin', !values.customerLogin);
                          adminToolSettings.disableFields &&
                            setAdminToolSettingHandler('customerLogin', setAdminToolSettings);
                        }}
                        id={'login-form-settings'}
                      />
                      <p>Show customer login form</p>
                    </div>
                    <div className={styles.customerLoginForm}>
                      <Field
                        isOn={values.customerTelephoneService}
                        component={AdminToggleSwitch}
                        handleToggle={() => {
                          adminToolSettings.disableFields &&
                            setFieldValue(
                              'customerTelephoneService',
                              !values.customerTelephoneService
                            );
                          adminToolSettings.disableFields &&
                            setAdminToolSettingHandler(
                              'customerTelephoneService',
                              setAdminToolSettings
                            );
                        }}
                        id={'telephone-settings'}
                      />
                      <p>Show Customer Services telephone number</p>
                    </div>
                    {values.customerTelephoneService && (
                      <>
                        <Field
                          component={AdminTelephoneInput}
                          label={'Telephone number:'}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setFieldValue('telephoneNumber', e.target.value);
                            setAdminToolSettings({
                              ...adminToolSettings,
                              telephoneNumber: e.target.value
                            });
                          }}
                          onBlur={handleBlur}
                          value={values.telephoneNumber}
                          id={'telephoneNumber'}
                          errorType={errors.telephoneNumber}
                          touched={touched.telephoneNumber}
                        />
                      </>
                    )}
                    <div className={styles.customerLoginForm}>
                      <Field
                        isOn={values.sisterBrandLink}
                        component={AdminToggleSwitch}
                        handleToggle={() => {
                          adminToolSettings.disableFields &&
                            setFieldValue('sisterBrandLink', !values.sisterBrandLink);
                          adminToolSettings.disableFields &&
                            setAdminToolSettingHandler('sisterBrandLink', setAdminToolSettings);
                        }}
                        id={'sister-brand-link'}
                      />
                      <p>Display link to sister brands</p>
                    </div>
                    <button
                      type="submit"
                      className={EnableAndDisableSaveButtonStyle}
                      disabled={!adminToolSettings.disableFields}>
                      {buttonTextMap(showCrisisToolDeactivationButton)}
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>

      {showConfirmationModal && (
        <ConfirmationContent
          handleConfirmationModal={handleConfirmationContentModal}
          prevAdminSettings={prevAdminToolSettingsRef.current}
          newAdminSettings={adminToolSettings}
          handleUpdateSettings={handleUpdateSettings}
          isDeactivatedByButton={deactivatedByButton}
        />
      )}
      <ToastContainer />
    </div>
  );
};
export default Admin;
