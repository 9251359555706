import Admin from '../src/pages/admin/Admin';
import './App.css';
import Spinner from './components/spinner/Spinner';
import { Route, Routes, useNavigate } from 'react-router-dom';
//okta imports
import { Security, LoginCallback } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { oktaAuthConfig } from './utils/config';

function App() {
  const navigate = useNavigate();
  const oktaAuth = new OktaAuth(oktaAuthConfig);
  const restoreOriginalUri = async (_oktaAuth: any, originalUri: string) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin), { replace: true });
  };
  return (
    <div className="App">
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <Routes>
          <Route path="/" element={<Admin />} />
          <Route path="/login/callback" element={<LoginCallback />} />
          <Route path="*" element={<Admin />} />
        </Routes>
        <Spinner />
      </Security>
    </div>
  );
}

export default App;
