import styles from './Spinner.module.css';
import { usePromiseTracker } from 'react-promise-tracker';

const Spinner = () => {
  const { promiseInProgress } = usePromiseTracker();
  return (
    <div data-testid="spinnerTestId">
      {promiseInProgress && (
        <div data-testid="spinner" className={styles['pos-center']}>
          <div className={styles.loader}></div>
        </div>
      )}
    </div>
  );
};

export default Spinner;
