export const serviceConstants = {
  OKTA_TOKEN_KEY: 'okta-token-storage'
};
export const apiConstants = {
  UNAUTHORIZED: 'Unauthorized',
  FETCH_FAIL: 'Fetch Fail'
};

export const toastConstants = {
  ADMIN_SETTINGS_UPDATE_FAIL: 'Admin settings update fail',
  ADMIN_SETTINGS_UPDATE_SUCCESSFULLY: 'Admin settings are updated successfully',
  ADMIN_SETTINGS_VALIDATION:
    'Advance setting should be enabled and minimum one setting should be on!',
  NO_CHANGE_ADMIN_SETTINGS: 'No change in admin settings'
};

export const alerts = {
  SUCCESS: 'Success',
  ERROR: 'Error'
};

export const errorConstants = {
  INVALID_INPUT: 'Please enter a valid telephone number.'
};

export const alertIcon = {
  VectorSuccess: 'VectorSuccess',
  VectorError: 'VectorError'
};
