import React from 'react';
import styles from './AdminToolContent.module.css';

const AdminToolContent = () => {
  const travellerReservationWebUrl = process.env.REACT_APP_TRAVELLER_RESERVATION_WEB_URL || ' ';
  return (
    <div className={styles.adminToolContent} data-testid="adminToolContentTestId">
      <p>
        This is the administration interface for the <strong>ABU Crisis Tool</strong>, for use
        during periods of severe and prolonged downtime of key Booking.com services such as the
        frontend website, extranet, databases, and other critical infrastructure.
      </p>
      <p>
        If the current situation does not meet this criteria, you should <strong>not</strong> use
        this page.
      </p>
      <p>
        If you are authorised to make changes to this tool, please make any updates using the form
        on the right. Any changes you make will not take effect until you click “Save changes”.
      </p>
      <p>You can view the current live version of the tool here:</p>
      <div className={styles.linkSplashPagesContainer}>
        <p className={styles.linkSplashPages}>
          <a href={travellerReservationWebUrl} target={'_blank'} rel="noreferrer">
            <strong>ABU Crisis Tool </strong>
          </a>
        </p>
        <p>(opens in new tab)</p>
      </div>
    </div>
  );
};

export default AdminToolContent;
