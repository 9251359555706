import styles from './AdminToggleSwitch.module.css';
import React from 'react';

interface IAdminProps {
  isOn: boolean;
  handleToggle: React.ChangeEventHandler<HTMLInputElement>;
  id: string;
}

const AdminToggleSwitch = ({ isOn, handleToggle, id }: IAdminProps) => {
  const adminStyle = () => {
    if (isOn) {
      return '#0071C2';
    } else {
      return '';
    }
  };
  return (
    <>
      <input
        checked={isOn}
        onChange={handleToggle}
        className={styles['react-switch-checkbox']}
        id={id}
        name={id}
        type="checkbox"
        data-testid={id}
      />
      <label
        id={'checkBox_' + id}
        data-testid={'checkBox_' + id}
        style={{ background: adminStyle() }}
        className={styles['react-switch-label']}
        htmlFor={id}>
        <span className={styles['react-switch-button']} />
      </label>
    </>
  );
};

export default AdminToggleSwitch;
