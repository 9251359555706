import React from 'react';
import Modal from '../modal/Modal';
import styles from './ConfirmationContent.module.css';
import VectorForwardArrowImg from '../../assets/images/VectorForwardArrow.png';
import { IPrevAdminToolSettingsState, IAdminToolFormValues } from '../../interfaces';
import { adminToolStateKeyName, stateMapping, truncate } from '../../utils/utils';

interface IConfirmationContent {
  handleConfirmationModal: (flag: boolean) => void;
  prevAdminSettings: IPrevAdminToolSettingsState;
  newAdminSettings: IAdminToolFormValues;
  handleUpdateSettings: (flag: boolean) => void;
  isDeactivatedByButton: boolean;
}
const ConfirmationContent = ({
  handleConfirmationModal,
  prevAdminSettings,
  newAdminSettings,
  handleUpdateSettings,
  isDeactivatedByButton
}: IConfirmationContent) => {
  const renderAdminToolState = (
    prevState: boolean | string,
    nextState: boolean | string,
    key: string
  ) => {
    if (prevState !== nextState) {
      if (key === 'telephoneNumber') {
        return (
          <div className={styles.adminToolConfigContainer}>
            <div className={styles.adminToolConfigContentContainer}>
              <p>{adminToolStateKeyName(key)}</p>
            </div>
            <div className={styles.adminToolTelephoneNumberStateContainer}>
              <p>
                <strong>{truncate(String(nextState), 20)}</strong>
              </p>
            </div>
          </div>
        );
      } else {
        return (
          <div className={styles.adminToolConfigContainer}>
            <div className={styles.adminToolConfigContentContainer}>
              <p>{adminToolStateKeyName(key)}</p>
            </div>
            <div className={styles.adminToolStateContainer}>
              <p>
                <strong>{stateMapping(Boolean(prevState))}</strong>
              </p>
              <img
                className={styles.VectorForwardArrowImg}
                src={VectorForwardArrowImg}
                alt={'VectorForwardArrow'}
              />
              <p>
                <strong>{stateMapping(Boolean(nextState))}</strong>
              </p>
            </div>
          </div>
        );
      }
    } else {
      return null;
    }
  };
  const closeModalHandler = () => {
    handleConfirmationModal(false);
    handleUpdateSettings(false);
    window.location.reload();
  };

  const onSaveHandler = () => {
    handleConfirmationModal(false);
    handleUpdateSettings(true);
  };

  return (
    <Modal onClose={closeModalHandler}>
      <div
        className={styles.confirmationContentContainer}
        data-testid="confirmationContentDivTestId">
        <h3>
          <strong className={styles.confirmationContentHeaderText}>Confirmation</strong>
        </h3>
        <p className={styles.confirmationContentText}>
          You are about to make the following changes:
        </p>
        {renderAdminToolState(
          prevAdminSettings.disableFields,
          newAdminSettings.disableFields,
          'disableFields'
        )}
        {!isDeactivatedByButton &&
          renderAdminToolState(
            prevAdminSettings.customerLogin,
            newAdminSettings.customerLogin,
            'customerLogin'
          )}
        {!isDeactivatedByButton &&
          renderAdminToolState(
            prevAdminSettings.customerTelephoneService,
            newAdminSettings.customerTelephoneService,
            'customerTelephoneService'
          )}
        {!isDeactivatedByButton &&
          renderAdminToolState(
            prevAdminSettings.sisterBrandLink,
            newAdminSettings.sisterBrandLink,
            'sisterBrandLink'
          )}
        {!isDeactivatedByButton &&
          newAdminSettings.customerTelephoneService &&
          renderAdminToolState(
            prevAdminSettings.telephoneNumber,
            newAdminSettings.telephoneNumber,
            'telephoneNumber'
          )}
        <p className={styles.confirmationContentText}>
          Click the button below to confirm you understand the effect your changes will have.
        </p>
        <div className={styles.confirmationContentButtonContainer}>
          <button
            data-testid="confirmationButtonTestId"
            className={styles.confirmationButton}
            onClick={onSaveHandler}>
            Yes I understand, make this change live
          </button>
          <button
            data-testid="cancelButtonTestId"
            className={styles.cancelButton}
            onClick={closeModalHandler}>
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationContent;
