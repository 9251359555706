import React from 'react';
import styles from './AdminTelephoneInput.module.css';
import { errorConstants } from '../../utils/constant';
import classNames from 'classnames';

interface IAdminTelephoneInputProps {
  label: string;
  id: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onBlur: React.FocusEventHandler;
  value: string;
  errorType: string | undefined;
  touched: boolean | undefined;
}

const AdminTelephoneInput = (props: IAdminTelephoneInputProps) => {
  const { label, onChange, onBlur, id, value, errorType, touched } = props;
  const telephonicInputStyle = classNames(styles.adminTelephoneInput, {
    [styles.errorInputFieldStyle]: errorType
  });
  return (
    <div className={styles.adminTelephone} data-testid="adminTelephoneTestId">
      <label htmlFor={id}>{label}</label>
      <input
        className={telephonicInputStyle}
        id={id}
        type={'text'}
        placeholder={'+44 20 3320 2609'}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
      />
      {errorType && touched && (
        <p className={styles.invalidInputStyle}>{errorConstants.INVALID_INPUT}</p>
      )}
    </div>
  );
};

export default AdminTelephoneInput;
