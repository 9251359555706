import { IAdminsettings } from './interfaces';
interface IAccessToken {
  accessToken: string;
}
export const readBookingAdminToolSettings = async () => {
  try {
    const response = await fetch(process.env.REACT_APP_ADMIN_READ_SETTINGS as string, {
      method: 'GET'
    });
    return response.json();
  } catch (error) {
    return (error as Error).message;
  }
};

export const saveBookingAdminToolSettings = async (
  adminSettings: IAdminsettings,
  token: IAccessToken,
  accessToken: string
) => {
  try {
    const response = await fetch(process.env.REACT_APP_ADMIN_SAVE_SETTINGS as string, {
      method: 'POST',
      body: JSON.stringify(adminSettings),
      headers: {
        Authorization: `Bearer ${token.accessToken ? token.accessToken : accessToken}`
      }
    });
    return response.json();
  } catch (error) {
    return (error as Error).message;
  }
};
