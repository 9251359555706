import React from 'react';
import ReactDOM from 'react-dom';
import styles from './Modal.module.css';

interface IBackdrop {
  onClose: () => void;
}
interface IModalOverlay {
  children: JSX.Element | JSX.Element[];
}

interface IModal extends IBackdrop, IModalOverlay {}
const Backdrop = ({ onClose }: IBackdrop) => {
  return <div data-testid="modalBackdropDivTestId" className={styles.backdrop} onClick={onClose} />;
};
const ModalOverlay = ({ children }: IModalOverlay) => {
  return (
    <div data-testid="modalOverlyDivTestId" className={styles.modal}>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

let portalElement = document.getElementById('overlays') as HTMLElement;

if (!portalElement) {
  portalElement = document.createElement('div');
  portalElement.setAttribute('id', 'overlays');
  document.body.appendChild(portalElement);
}

const Modal = ({ onClose, children }: IModal) => {
  return (
    <>
      {ReactDOM.createPortal(<Backdrop onClose={onClose} />, portalElement)}
      {ReactDOM.createPortal(<ModalOverlay>{children}</ModalOverlay>, portalElement)}
    </>
  );
};

export default Modal;
