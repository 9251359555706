import styles from './Header.module.css';
import bookingAdminLogo from '../../assets/images/Booking_Com_Logotype_Aug2020_Blue 1.png';
import VectorSignout from '../../assets/images/VectorSignout.png';
import classNames from 'classnames';
import React from 'react';
import { useOktaAuth } from '@okta/okta-react';

interface IHeaderProps {
  activateAdminToolStatus: boolean;
}
const Header = ({ activateAdminToolStatus }: IHeaderProps) => {
  const { oktaAuth } = useOktaAuth();
  const bookingAdminStatus = activateAdminToolStatus ? 'ACTIVE' : 'INACTIVE';
  const bookingAdminStatusStyle = classNames(
    { [styles.enabledAdminToolStatus]: activateAdminToolStatus },
    { [styles.disabledAdminToolStatus]: !activateAdminToolStatus }
  );

  return (
    <div className={styles.header} data-testid="headerTestId">
      <div className={styles.bookingLogoContainer}>
        <div className={styles.adminHeaderAndLogoContainer}>
          <img
            className={styles.bookingAdminLogo}
            src={bookingAdminLogo}
            alt={'bookingAdminLogo'}
          />
          <p className={styles.abuLogoHeader}>
            <strong>ABU Crisis Tool</strong>
          </p>
        </div>
        <div className={styles.adminSignoutContainer} onClick={() => oktaAuth.signOut()}>
          <button className={styles.adminSignoutButtonStyle}>Sign out</button>
          <img className={styles.vectorSignoutImgStyle} src={VectorSignout} alt={'VectorSignout'} />
        </div>
      </div>
      <div className={styles.bookingContinuityStatusContainer}>
        <p className={styles.bookingContinuityStatus}>Current status:</p>
        <p className={bookingAdminStatusStyle} data-testid="bookingAdminStatusTestId">
          {bookingAdminStatus}
        </p>
      </div>
    </div>
  );
};

export default Header;
